import React from 'react'
import Navbar from '../component/landing/Navbar'
import Footer from '../component/landing/Footer'

const Privacy = () => {
    return (
        <div className='w-full h-fit bg-white'>
            <div>
                <Navbar />
            </div>
            
            
            <div className='p-6 tracking-wider flex flex-col gap-3
            lg:px-16'>
                <p className='font-bold text-xl'>
                QuickCare Privacy Policy
                </p>

                <p className='font-medium'>
                This Privacy Policy outlines how Centurion Technologies ("Provider") collects, uses, discloses, and
                protects your personal information when you use QuickCare, an innovative health information
                platform integrated with the Safaricom network in Kenya. By accessing or using QuickCare, you
                consent to the collection, use, and disclosure of your personal information as described in this
                Privacy Policy.
                </p>

                <p className='font-bold text-xl'>
                1. Information We Collect:
                </p>

                <p className='font-medium'>
                Personal Information: When you use QuickCare, we may collect personal information such as
                your name, age, gender, contact information, and health-related data provided during
                interactions with our ChatBots. <br /> <br />
                Usage Data: We may also collect information about how you use QuickCare, including your
                interactions with the platform, IP address, device information, and browser type.
                </p>

                <p className='font-bold text-xl'>
                2. Use of Information:
                </p>

                <p className='font-medium'>
                We use the information we collect for the following purposes:
                </p>

                <ul className='font-medium list-disc ml-4'>
                    <li>
                        Providing and maintaining QuickCare services.
                    </li>

                    <li>
                    Delivering personalized health tips and information based on your preferences.
                    </li>

                    <li>
                    Improving the quality and effectiveness of our services.
                    </li>

                    <li>
                    Analyzing trends and usage patterns to enhance user experience.
                    </li>

                    <li>
                    Responding to user inquiries and providing customer support.
                    </li>

                    <li>
                    Complying with legal obligations and protecting our rights.
                    </li>
                </ul>

                <p className='font-bold text-xl'>
                3. Disclosure of Information:
                </p>

                <p className='font-medium'>
                We may disclose your personal information in the following circumstances:
                </p>

                <ul className='font-medium list-disc ml-4'>
                    <li>
                    With your consent: We may share your information with third parties when you give us
                    explicit consent to do so.
                    </li>

                    <li>
                    Service Providers: We may engage third-party service providers to assist us in delivering
                    QuickCare services, and they may have access to your personal information.
                    </li>

                    <li>
                    Legal Requirements: We may disclose your information if required by law or in response
                    to valid legal requests, such as court orders or subpoenas.
                    </li>

                    <li>
                    Protection of Rights: We may disclose your information to protect our rights, property, or
                    safety, as well as the rights, property, or safety of others.
                    </li>
                </ul>

                <p className='font-bold text-xl'>
                4. Data Security:
                </p>

                <p className='font-medium'>
                We take appropriate technical and organizational measures to protect your personal information
                from unauthorized access, alteration, disclosure, or destruction. However, please note that no
                method of transmission over the internet or electronic storage is 100% secure, and we cannot
                guarantee absolute security.
                </p>

                <p className='font-bold text-xl'>
                5. Data Retention:
                </p>

                <p className='font-medium'>
                We will retain your personal information only for as long as necessary to fulfill the purposes
                outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
                </p>

                <p className='font-bold text-xl'>
                6. Third-Party Links:
                </p>

                <p className='font-medium'>
                QuickCare may contain links to third-party websites or services that are not operated by us. We
                have no control over the content, privacy policies, or practices of these third-party sites and
                encourage you to review their privacy policies before providing any personal information.
                </p>

                <p className='font-bold text-xl'>
                7. Children's Privacy:
                </p>

                <p className='font-medium'>
                QuickCare is not intended for use by children under the age of 13. We do not knowingly collect
                personal information from children under 13. If you are a parent or guardian and believe that
                your child has provided us with personal information, please contact us, and we will take steps
                to remove that information from our systems.
                </p>

                <p className='font-bold text-xl'>
                8. Changes to this Privacy Policy:
                </p>

                <p className='font-medium'>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page,
                and the effective date will be updated accordingly. We encourage you to review this Privacy
                Policy periodically for any updates or changes.
                </p>

                <p className='font-bold text-xl'>
                9. Contact Us:
                </p>

                <p className='font-medium'>
                If you have any questions or concerns about this Privacy Policy or our privacy practices, please
                contact us at 
                </p>

                <p className='font-medium'>
                Address: Royal Oak. flat 5A Lavington Nairobi.
                </p>

                <p className='font-medium'>
                Phone: +254 794 867517.
                </p>

                <p className='font-medium'>
                Thank you for trusting QuickCare with your health information. We are committed to protecting
                your privacy and providing you with the best possible experience
                </p>

            </div>

            <div className='mt-6 w-full '>
            <Footer />
            </div>
        </div>
    )
}

export default Privacy
