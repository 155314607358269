import React, { useState } from 'react';
import Header from '../component/Header';
import Sidebar from '../component/Sidebar';
import ChatHistory from '../component/ChatHistory';


const History = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className='w-full bg-[#F9FAFB]'>
            <div className='fixed w-full'>
                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  
            </div>

            <div className={`pt-16 md:pt-20 w-full ${isSidebarOpen ? 'md:pt-20 md:w-3/4 lg:w-4/5' : 'md:w-3/4 lg:w-4/5 xl:w-5/6'}`}>
                <div className={`${isSidebarOpen ? 'md:block z-50' : 'hidden md:block z-50'}`}>
                    <Sidebar />
                </div>

                <div className='md:ml-48 p-6 xl:ml-60  w-full'>
                    <ChatHistory />
                </div>
            </div>
        </div>
    );
};

export default History;
