import React, { useEffect, useState } from 'react';
import { getToken } from '../utils/tokenHelper';
import CloseIcon from '@mui/icons-material/Close';

const EditProfileModal = ({ isOpen, onClose }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [address, setAddress] = useState('');
    const [gender, setGender] = useState('male'); // default to male
    const [response, setResponse] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
    
        const token = getToken();
        console.log('Retrieved token:', token);  // Log the retrieved token
        if (!token) {
            console.error('No token found');
            setResponse('Error: No token found');
            return;
        }
    
        // Prepare the request headers
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
    
        // Prepare the request body
        const requestBody = {
            first_name: firstName,
            last_name: lastName,
            date_of_birth: dateOfBirth,
            address: address,
            gender: gender
        };
    
        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify(requestBody),
            redirect: "follow"
        };
    
        // Log the details sent to the server
        console.log('Sending to server:', requestBody);
    
        fetch("https://api.quickcare.ng/api/v1/user/profile", requestOptions)
            .then(response => response.json())  // Parse response as JSON
            .then(result => {
                console.log('Response from server:', result);
                // Close the modal after successful submission
                onClose();
            })
            .catch(error => console.error('Error:', error));
    };
    

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-md w-full max-w-md">
                <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold">Edit Profile</h2>
                    <button onClick={onClose} className="text-xl font-bold">
                        <CloseIcon />
                    </button>
                </div>
                <div className="mt-4">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">First Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full border border-gray-300 rounded outline-0 p-2"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Last Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full border border-gray-300 rounded outline-0 p-2"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Date of Birth</label>
                            <input
                                type="date"
                                className="mt-1 block w-full border border-gray-300 rounded outline-0 p-2"
                                value={dateOfBirth}
                                onChange={(e) => setDateOfBirth(e.target.value)}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Address</label>
                            <input
                                type="text"
                                className="mt-1 block w-full border border-gray-300 rounded outline-0 p-2"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Gender</label>
                            <div className="mt-1">
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio h-4 w-4 text-[#069125]"
                                        name="gender"
                                        value="male"
                                        checked={gender === 'male'}
                                        onChange={() => setGender('male')}
                                    />
                                    <span className="ml-2">Male</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input
                                        type="radio"
                                        className="form-radio h-4 w-4 text-[#069125]"
                                        name="gender"
                                        value="female"
                                        checked={gender === 'female'}
                                        onChange={() => setGender('female')}
                                    />
                                    <span className="ml-2">Female</span>
                                </label>
                            </div>
                        </div>

                        <button type="submit" className="bg-[#069125] text-white font-medium py-2 px-4 rounded-md">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProfileModal;
