import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TestPage from './TestPage';

const SubscribeUser = () => {
  const [msisdn, setMsisdn] = useState('');
  const [ip, setIp] = useState('');
  const [userAgent, setUserAgent] = useState('');
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [cgUrl, setCgUrl] = useState('');

  useEffect(() => {
    // Get user agent
    setUserAgent(navigator.userAgent);
    console.log('User Agent:', navigator.userAgent); // Log the user agent

    // Fetch the IP address
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIp(response.data.ip);
        console.log('IP Address:', response.data.ip); // Log the IP address
      })
      .catch(error => {
        console.error("Error fetching IP address:", error);
        setError('Error fetching IP address');
      });
  }, []);

  useEffect(() => {
    console.log('MSISDN:', msisdn); // Log the msisdn value whenever it changes
  }, [msisdn]);

  const handleSubmit = () => {
    if (!msisdn) {
      setError('Error: No MSISDN found');
      return;
    }

    setLoading(true); // Set loading to true when request starts

    const raw = {
      msisdn,
      ip,
      user_agent: userAgent
    };

    console.log("Data Sent:", raw); // Log the data sent

    axios.post("https://api.quickcare.ng/api/v1/user/safaricom/subscribe", raw, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    })
    .then(response => {
      console.log("Data Received:", response.data); // Log the response data
      setResult(JSON.stringify(response.data));
      
      if (response.data && response.data.cg_url) {
        setCgUrl(response.data.cg_url);
        console.log("CG URL:", response.data.cg_url); // Log the cg_url
        window.location.href = response.data.cg_url;
      }
      
      setLoading(false); // Set loading to false when request ends
    })
    .catch(error => {
      console.error("Error:", error);
      setError(error.toString());
      setLoading(false); // Set loading to false if there is an error
    });
  };

  return (
    <div className='w-full flex flex-col items-start gap-3'>
      
      <TestPage onMsisdnFetched={setMsisdn} /> {/* Pass the callback function */}

      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} 
        className='w-full flex flex-col gap-3 items-center'>

        {loading && <div className='loader'>Loading...</div>} {/* Loader */}

        <div className='flex flex-col gap-2 w-full hidden'>
          <label className='text-lg font-semibold '>
            MSISDN:
          </label>
          <input type="text" value={msisdn} readOnly 
            className='border border-[#292929] rounded p-2 outline-0'/>
        </div>

        <div className='flex flex-col gap-2 w-full hidden'>
          <label className='text-lg font-semibold'>
            IP:
          </label>
          <input type="text" value={ip} readOnly 
            className='border border-[#292929] rounded p-2 outline-0'/>
        </div>

        <div className='flex flex-col gap-2 w-full hidden'>
          <label className='text-lg font-semibold'>
            User Agent:
          </label>
          <input type="text" value={userAgent} readOnly 
            className='border border-[#292929] rounded p-2 outline-0'/>
        </div>

        <button type="submit" disabled={loading}
          className='py-3 px-6 w-full mt-1 bg-[#069125] text-white font-medium rounded uppercase'>
          {loading ? 'Subscribing...' : 'Subscribe'}
        </button>

        {result && <div className='text-green-500 font-semibold'>Success</div>}
        {error && <div className='text-red-500 font-semibold'>{error}</div>}
      </form>
    </div>
  );
};

export default SubscribeUser;
