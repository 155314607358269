import React, { useState } from 'react';
import Header from '../component/Header';
import Sidebar from '../component/Sidebar';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Insurance from '../component/Insurance';
import Payment from '../component/Payment';

const Wallet = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isBalanceVisible, setIsBalanceVisible] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleBalanceVisibility = () => {
        setIsBalanceVisible(!isBalanceVisible);
    };

    const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
    const [isSubscriptionVisible, setIsSubscriptionVisible] = useState(false);

    const togglePersonalInfo = () => {
        setIsPersonalInfoVisible(true);
        setIsSubscriptionVisible(false);
    };

    const toggleSubscription = () => {
        setIsPersonalInfoVisible(false);
        setIsSubscriptionVisible(true);
    };

    return (
        <div className='w-full bg-[#F9FAFB]'>
            <div className='fixed w-full'>
                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  
            </div>

            <div className={`pt-16 md:pt-20 w-full ${isSidebarOpen ? 'md:pt-20 md:w-3/4 lg:w-4/5' : 'md:w-3/4 lg:w-4/5 xl:w-5/6'}`}>
                <div className={`${isSidebarOpen ? 'md:block z-50' : 'hidden md:block z-50'}`}>
                    <Sidebar />
                </div>

                <div className='md:ml-48 p-6 xl:ml-60  w-full '>
                    <div className='w-full flex items-center justify-between'>
                        <div className='flex items-center gap-2'>
                            <p>
                                Wallet Balance: 
                            </p>

                            {isBalanceVisible ? (
                                <p>
                                    N10,000
                                </p>
                            ) : (
                                <p>******</p>
                            )}
                            
                            <button title='balance' onClick={toggleBalanceVisibility}>
                                {isBalanceVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                            </button>
                        </div>

                        <div>              
                            <button className='flex gap-1 bg-[#069125] text-white font-medium py-1 px-2 items-center rounded-md text-sm md:text-base
                            md:py-2 md:px-4'>
                                <span>
                                    Fund Wallet
                                </span>
                            </button>
                       </div>
                    </div>
                    <div className='w-full flex items-center justify-between mt-4'>
                       <div className='flex gap-3 text-sm items-center md:text-base lg:gap-6'>
                            <button onClick={togglePersonalInfo} className={isPersonalInfoVisible ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium' : ''}>
                            Payment
                            </button>
                            <button onClick={toggleSubscription} className={isSubscriptionVisible ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium' : ''}>
                            Insurance 
                            </button>
                       </div>
                    </div>

                    {isPersonalInfoVisible && (
                        <div className='pb-96'>
                          <Payment />
                        </div>
                    )}

                    {isSubscriptionVisible && (
                        <div className=''>
                            <Insurance />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Wallet;
