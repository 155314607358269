import React, { useState, useEffect } from 'react';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for loading indicator
import { getToken } from '../utils/tokenHelper';
import { getSymptoms } from '../utils/symptomHelper';

const ChatWithAI = () => {
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const [symptoms, setSymptoms] = useState(''); // Add state to store symptoms

    useEffect(() => {
        // Retrieve symptoms when component mounts
        const retrievedSymptoms = getSymptoms();
        setSymptoms(JSON.stringify(retrievedSymptoms)); // Convert symptoms to a string
        console.log('Retrieved Symptoms:', retrievedSymptoms); // Log the retrieved symptoms

        // Function to initiate AI conversation with symptoms
        const initiateConversation = async () => {
            setIsLoading(true); // Set loading to true when a request is made

            const token = getToken();
            console.log('Retrieved token on initiate:', token);  // Log the retrieved token
            if (!token) {
                console.error('No token found');
                setResponse('Error: No token found');
                setIsLoading(false); // Set loading to false if no token is found
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const raw = JSON.stringify({
                "message": "", // Send an empty message to initiate conversation
                "symptoms": JSON.stringify(retrievedSymptoms) // Convert symptoms to a string
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            try {
                const response = await fetch("https://api.quickcare.ng/api/v1/ai/chat", requestOptions);
                if (response.ok) {
                    const result = await response.json();
                    console.log('Server response:', result); // Log the server response
                    setResponse(result.output);
                    setChatHistory(prevHistory => [...prevHistory, { message: "AI initiated conversation", response: result.output }]);
                } else {
                    const errorText = await response.text();
                    console.error('Error response:', errorText); // Log error response
                    setResponse(`Error: ${errorText}`);
                }
            } catch (error) {
                console.error('Fetch error:', error); // Log fetch error
                setResponse(`Error: ${error.message}`);
            } finally {
                setIsLoading(false); // Set loading to false when the request completes
            }
        };

        initiateConversation();
    }, []); // Empty dependency array to run only on component mount

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Set loading to true when a request is made

        const token = getToken();
        console.log('Retrieved token on submit:', token);  // Log the retrieved token
        if (!token) {
            console.error('No token found');
            setResponse('Error: No token found');
            setIsLoading(false); // Set loading to false if no token is found
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify({
            "message": message,
            "symptoms": symptoms // Symptoms are already a string
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://api.quickcare.ng/api/v1/ai/chat", requestOptions);
            if (response.ok) {
                const result = await response.json();
                console.log('Server response:', result); // Log the server response
                setResponse(result.output);
                setChatHistory(prevHistory => [...prevHistory, { message, response: result.output }]);
                setMessage('');  // Clear the input field
            } else {
                const errorText = await response.text();
                console.error('Error response:', errorText); // Log error response
                setResponse(`Error: ${errorText}`);
            }
        } catch (error) {
            console.error('Fetch error:', error); // Log fetch error
            setResponse(`Error: ${error.message}`);
        } finally {
            setIsLoading(false); // Set loading to false when the request completes
        }
    };

    return (
        <div className='w-full h-full flex flex-col items-center relative pb-[500px]'>
            <div className='flex flex-col items-center justify-center p-4 w-full'>
                <div className='mt-20 flex items-center justify-center w-full md:px-5'>
                    <p className='py-1 px-2 rounded-lg bg-[#CDE9D3] text-sm md:text-base'>
                        Messages are generated by AI from QuickCare. You can improve the quality by sending us feedback. Click to learn more.
                    </p>
                </div>
                <div className='mt-10 w-full pb-16'>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className='mb-4 flex flex-col gap-3 pb-6 w-full'>
                            <p className='text-white font-medium self-end py-2 px-3 rounded-lg bg-[#02390E] text-lg'>
                                {chat.message}
                            </p>
                            <div className='w-2/3'>
                                <p className='text-white font-medium py-2 px-3 rounded-lg bg-[#728176] text-lg w-fit'>
                                    {chat.response}
                                </p>
                            </div>
                        </div>
                    ))}
                    {isLoading && (
                        <div className='flex justify-center w-full'>
                            <CircularProgress /> {/* Show loading spinner when isLoading is true */}
                        </div>
                    )}
                </div>
            </div>

            <form onSubmit={handleSubmit} className='bottom-0 left-0 fixed w-full flex h-16 bg-[#ECEDEE] items-center justify-between px-3 z-20
            lg:px-20'>
                <InsertEmoticonIcon />
                <input
                    type="text" 
                    name="message"
                    value={message}
                    onChange={handleChange}
                    placeholder="Type a Message"
                    className='w-4/5 bg-white text-[#777777] p-3 rounded-lg outline-0'
                    disabled={isLoading} // Disable input field when loading
                />
                <button type="submit" className='chat-submit' disabled={isLoading}>
                    <SendIcon />
                </button>
            </form>
        </div>
    );
};

export default ChatWithAI;
