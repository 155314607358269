import './App.css';
import Settings from './pages/Settings';
import History from './pages/History';
import Health from './pages/Health';
import Specialist from './pages/Specialist';
import LabResult from './pages/LabResult';
import Wallet from './pages/Wallet';
import LandingPage from './pages/LandingPage';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import ChatAI from './pages/ChatAI';
import Subscription from './pages/Subscription';
// import SubscribeUser from './component/SubscriptionKenya';

// Authentication screens
import Login from './pages/Login';
import Signup from './pages/Signup';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './component/PrivateRoute';  // Import PrivateRoute

import TestPage from './pages/TestPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms&conditions" element={<Terms />} />
        <Route path="/privacy&policies" element={<Privacy />} />
        {/* <Route path="/subscribe" element={<SubscribeUser />} /> */}
        <Route path="/test" element={<TestPage />} />
        <Route path="/settings" element={<Settings />} />

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/subscription" element={<Subscription />} />

        {/* Protected Routes */}
        {/* <Route path="/settings" element={<PrivateRoute element={Settings} />} /> */}
        <Route path="/health" element={<PrivateRoute element={Health} />} />
        <Route path="/history" element={<PrivateRoute element={History} />} />
        <Route path="/specialist" element={<PrivateRoute element={Specialist} />} />
        <Route path="/lab-result" element={<PrivateRoute element={LabResult} />} />
        <Route path="/wallet" element={<PrivateRoute element={Wallet} />} />
        <Route path="/consultation" element={<PrivateRoute element={ChatAI} />} />
      </Routes>
    </Router>
  );
}

export default App;
