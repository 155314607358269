import React, { useEffect, useState } from 'react';
import { getToken } from '../utils/tokenHelper';
import Profile from "../images/profile.png";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const PersonalInfo = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [profile, setProfile] = useState({
        first_name: '', last_name: '', address: '', date_of_birth: '', 
        gender: '', email: '', country: '', profile_image: null
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        const token = getToken();
        console.log('Retrieved token:', token);  // Log the retrieved token
        if (!token) {
            console.error('No token found');
            setResponse('Error: No token found');
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://api.quickcare.ng/api/v1/user/profile", requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then((result) => {
                console.log('Server response:', result); // Log the result to the console
                setProfile(result.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching profile:', error); // Log the error to the console
                setError(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        console.log('Profile image:', profile.profile_image);
    }, [profile]);

    const handleImageChange = async (event) => {
        const imageFile = event.target.files[0];
        if (imageFile) {
            const imageUrl = URL.createObjectURL(imageFile);
            setSelectedImage(imageUrl);

            // Get token
            const token = getToken();
            console.log('Retrieved token for upload:', token);  // Log the retrieved token

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const formdata = new FormData();
            formdata.append("file", imageFile);

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            try {
                const response = await fetch("https://api.quickcare.ng/api/v1/user/profile/image", requestOptions);
                if (!response.ok) {
                    if (response.status === 413) {
                        throw new Error('File is too large');
                    }
                    throw new Error('Failed to upload image');
                }
                const result = await response.json();
                console.log('Image upload response:', result); // Log the result to the console
                setStatusMessage('Image uploaded successfully!');

                // Reload the page after 1 second
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (error) {
                console.error('Error uploading image:', error); // Log the error to the console
                setStatusMessage('File is too large');
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className='w-full h-fit flex flex-col gap-4 border border-gray-300 rounded mt-4 bg-white mb-96'>
            <div className='w-full border-b border-gray-300'>
                <div className='p-2 lg:p-6 flex w-full items-center justify-between text-sm gap-3
                md:w-2/3 md:text-base lg:w-3/5 xl:w-2/5'>
                    <div className='flex flex-col gap-1'>
                        <p className='font-semibold'>
                        Profile Photo
                        </p>

                        <p className='text-gray-500'>
                            This image will be displayed on your profile
                        </p>

                        <label htmlFor="image-upload" className="cursor-pointer border border-[#069125] w-2/3 text-[#069125] font-medium py-2 
                        flex items-center gap-1 px-1 rounded text-sm justify-center
                        hover:bg-[#069125] hover:text-white">
                            <ImageOutlinedIcon />
                            <span>
                                Change Photo
                            </span>
                        </label>

                        <p className='mt-4'>
                            File should not be more than 5mb
                        </p>

                        {statusMessage && (
                            <div className='px-2 lg:px-6 py-2'>
                                <p className={`text-sm ${statusMessage.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>
                                    {statusMessage}
                                </p>
                            </div>
                        )}
                        <input id="image-upload" type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
                    </div>

                    {selectedImage ? (
                        <img src={selectedImage} alt="Profile" className='w-16 h-16 rounded-full' />
                    ) : profile.profile_image ? (
                        <img src={profile.profile_image} alt="Profile" className='w-16 h-16 rounded-full' />
                    ) : (
                        <AccountCircleIcon style={{ fontSize: 64 }} />
                    )}
                </div>
            </div>

            <div className='px-2 lg:px-6 pt-1 pb-6 lg:pb-10 flex flex-col gap-3 w-full text-sm md:text-base'>
                <p className='font-semibold'>
                Personal Information
                </p>

                <div className=''>
                    <p className='text-gray-400'>
                    Name
                    </p>

                    <p>
                    {profile.last_name} {profile.first_name}
                    </p>
                </div>

                <div className=''>
                    <p className='text-gray-400'>
                    Email
                    </p>

                    <p>
                    {profile.email}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                    Date of Birth
                    </p>

                    <p>
                    {profile.date_of_birth}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'> 
                    Gender
                    </p>

                    <p className='capitalize'>
                    {profile.gender}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                    Home Address
                    </p>

                    <p>
                    {profile.address}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                   Country
                    </p>

                    <p className='capitalize'>
                    {profile.country}
                    </p>
                </div>
            </div>

            
        </div>
    );
}

export default PersonalInfo;
