import React, { useState } from 'react';
import Header from '../component/Header';
import Sidebar from '../component/Sidebar';
import data from '../component/data/specialist';



const Specialist = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    

    return (
        <div className='w-full bg-[#F9FAFB]'>
            <div className='fixed w-full'>
                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  
            </div>

            <div className={`pt-16 md:pt-20 w-full ${isSidebarOpen ? 'md:pt-20 md:w-3/4 lg:w-4/5' : 'md:w-3/4 lg:w-4/5 xl:w-5/6'}`}>
                <div className={`${isSidebarOpen ? 'md:block z-50' : 'hidden md:block z-50'}`}>
                    <Sidebar />
                </div>

                <div className='pb-96 md:ml-48 p-6 xl:ml-60  w-full flex flex-col gap-7 
                 mt-4 '>
                    <p className='font-semibold'>
                        Find here on our list a Specialist that suits your needs
                    </p>

                    <div className='w-full flex flex-col gap-4 
                  lg:grid lg:grid-cols-2'>
                    {data.map((item, index) => (
                        <div key={index} className='w-full flex flex-col border brorder-gray-300 rounded-lg bg-white text-sm
                        p-3  items-start
                        md:text-base'>
                            <div className='w-full flex items-center justify-between h-4/5'>
                                <div className='flex items-start w-full gap-4 '>
                                    <img src={item.profileImage} alt="" className='w-10 h-10'/>

                                    <div className='flex flex-col gap-1'>
                                        <p className='font-semibold'>
                                        {item.Name}
                                        </p>
                                        <p className='text-[#069125] font-semibold'>
                                        {item.Title}
                                        </p>
                                        <p className='text-gray-400'>
                                        {item.Message}
                                        </p>
                                    </div>
                                </div>

                            </div>

                           <button className='mt-3 bg-[#069125] text-white font-medium py-2 px-3 rounded-lg '>
                            Start a Consultation
                           </button>
                            
                           
                        </div>
                    ))}
                  </div>
                </div>
            </div>
        </div>
    );
};

export default Specialist;
