import React, { useEffect } from 'react';
import SubscribeUser from '../component/SubscriptionKenya';
import Logo from "../images/logo/Quick-Care.png";
import Sponsor from '../images/sponsor-pic.png';
import { Link } from 'react-router-dom';
import { saveMarketerInfo } from '../utils/marketers'; // Import the function

const Subscription = () => {
    
    useEffect(() => {
        // Get URL parameters
        const searchParams = new URLSearchParams(window.location.search);
        const trxid = searchParams.get('trxid');
        const productId = searchParams.get('product_id');

        // Log trxid and productId for debugging
        console.log('trxid:', trxid);
        console.log('productId:', productId);

        // If trxid and product_id exist, save them using the function
        if (trxid && productId) {
            saveMarketerInfo(trxid, productId); // Save to localStorage
        }
    }, []);

    return (
        <div className='w-full h-screen bg-white'>
            <div className='w-full p-6 h-full flex items-center justify-center gap-4 mt-2 lg:p-8'>
                <div className='w-full md:w-3/4 lg:w-1/2 lg:mt-8 h-full flex flex-col items-center justify-between'>
                    <div className='flex w-full items-center justify-between p-4 bg-[#069125] text-white rounded-xl md:hidden lg:flex xl:w-1/2'>
                        <p className='text-xl font-semibold tracking-wide flex flex-col items-center'>
                            <span>Get better health</span>
                            <span>with Quickcare</span>
                        </p>
                        <img src={Sponsor} alt="Sponsor" className='w-32 h-32 lg:w-32 lg:h-32' />
                    </div>

                    <div className='flex flex-col gap-2 items-center text-center'>
                        <Link to='/'>
                            <img src={Logo} alt="Quick Care Logo" className='w-[150px] h-auto'/>
                        </Link>

                        <p className='text-lg font-medium'>
                            Quick Care, your trusted partner for instant health solutions.
                        </p>
                    </div>

                    <div className='flex flex-col text-sm w-full items-center text-center gap-2'>
                        <SubscribeUser />
                        <p className='md:w-4/5'>
                            Your health is our priority, and by subscribing, you empower yourself with the tools and knowledge to take control of your well-being.
                        </p>
                        <p>
                            Cost: 12KES/daily 
                        </p>    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Subscription;
