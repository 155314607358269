import React from 'react';
import data from "../component/data/subscription";
import SubscribeUser from './SubscriptionKenya';
import { getToken, getUsername } from '../utils/tokenHelper';

const Subscription = () => {
    const handleSubscribeClick = (plan, price) => {
        console.log(`Plan: ${plan}, Price: ${price}`);

        const token = getToken();
        const phoneNumber = `+${getUsername()}`;

        console.log("Token:", token);
        console.log("Phone Number:", phoneNumber);

        if (plan === "Basic Plan") {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const raw = JSON.stringify({
                "plan_type": "daily",
                "phone_number": phoneNumber
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            console.log("Data sent:", raw);

            fetch("https://api.quickcare.ng/api/v1/user/paystack/subscribe", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log("Server response:", result);
                    if (result.data && result.data.url) {
                        window.location.href = result.data.url;
                    }
                })
                .catch((error) => console.error("Error:", error));
        }
    };

    return (
        <div className='mt-6 text-sm w-full flex flex-col gap-4'>
           <p>
           Subscribe now to gain access to premium medical consultation services and enjoy the benefits of personalized healthcare.
           </p>

           <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
                {data.map((subscription, index) => (
                        <div key={index} className='bg-white rounded-lg flex flex-col items-start h-60 justify-between'>
                            <div className='w-full bg-[#F6F6F6] px-6 py-3 rounded-t-lg flex flex-col gap-2'>
                                <p className='font-semibold'>
                                    {subscription.Plan}: {subscription.Price}
                                </p>
                                <div>
                                    <p className='font-semibold underline'>
                                    Features
                                    </p>
                                    <ul className='ml-5'>
                                        {subscription.Feature.map((feature, index) => (
                                            <li key={index} className='list-disc '>
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>   
                            </div>

                            <div className='px-6 py-3 flex items-center justify-center'>
                                {subscription.Plan === "Basic Plan" ? (
                                    <a
                                        href="/subscription"
                                        onClick={() => handleSubscribeClick(subscription.Plan, subscription.Price)}
                                        className='text-[#069125] border border-[#069125] font-medium p-2 rounded 
                                        hover:bg-[#069125] hover:text-white'>
                                        Subscribe Now
                                    </a>
                                ) : (
                                    <button
                                        disabled
                                        className='text-gray-400 border border-gray-400 font-medium p-2 rounded cursor-not-allowed'>
                                        Coming Soon
                                    </button>
                                )}
                            </div>
                        </div>
                ))}
           </div>

           {/* <div className='w-full flex flex-col gap-3 items-start mt-4'>
                <p className='font-semibold'>
                    Payment Method
                </p>
           </div> */}

           {/* <div className='w-full bg-white h-fit p-3 rounded-lg lg:w-1/2'>
            <SubscribeUser />
           </div> */}
        </div>
    );
};

export default Subscription;
