import React, { useState } from 'react';
import Header from '../component/Header';
import Sidebar from '../component/Sidebar';
import CreateIcon from '@mui/icons-material/Create';
import PersonalInfo from '../component/PersonalInfo';
import Subscription from '../component/Subscription';
import ChangePassword from '../component/ChangePassword';
import EditProfileModal from '../component/EditProfileModal';

const Settings = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
    const [isSubscriptionVisible, setIsSubscriptionVisible] = useState(false);
    const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const togglePersonalInfo = () => {
        setIsPersonalInfoVisible(true);
        setIsSubscriptionVisible(false);
        setIsChangePasswordVisible(false);
    };

    const toggleSubscription = () => {
        setIsPersonalInfoVisible(false);
        setIsSubscriptionVisible(true);
        setIsChangePasswordVisible(false);
    };

    const toggleChangePassword = () => {
        setIsPersonalInfoVisible(false);
        setIsSubscriptionVisible(false);
        setIsChangePasswordVisible(true);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='w-full bg-[#F9FAFB]'>
            <div className='fixed w-full'>
                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  
            </div>

            <div className={`pt-16 md:pt-20 w-full ${isSidebarOpen ? 'md:pt-20 md:w-3/4 lg:w-4/5' : 'md:w-3/4 lg:w-4/5 xl:w-5/6'}`}>
                <div className={`${isSidebarOpen ? 'md:block z-50' : 'hidden md:block z-50'}`}>
                    <Sidebar />
                </div>

                <div className='md:ml-48 p-6 xl:ml-60  w-full'>
                    <div className='w-full flex items-center justify-between '>
                        <div className='flex gap-3 text-sm items-center md:text-base lg:gap-6'>
                            <button onClick={togglePersonalInfo} className={isPersonalInfoVisible ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium' : ''}>
                                Personal Info
                            </button>
                            <button onClick={toggleSubscription} className={isSubscriptionVisible ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium' : ''}>
                                Subscription
                            </button>

                            <button onClick={toggleChangePassword} className={isChangePasswordVisible ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium' : ''}>
                                Change Password 
                            </button>
                        </div>

                        <div>
                            {isPersonalInfoVisible && (
                                <button onClick={openModal} className='flex gap-1 bg-[#069125] text-white font-medium py-1 px-2 items-center rounded-md text-sm md:text-base md:py-2 md:px-4'>
                                    <CreateIcon className=''/>
                                    <span>Edit Profile</span>
                                </button>
                            )}

                            {isSubscriptionVisible && (
                                <button className='flex gap-1 bg-[#069125] text-white font-medium py-1 px-2 items-center rounded-md text-sm md:text-base md:py-2 md:px-4'>
                                    <span>Fund Wallet</span>
                                </button>
                            )}
                        </div>
                    </div>

                    {isPersonalInfoVisible && (
                        <div>
                            <PersonalInfo />
                        </div>
                    )}

                    {isSubscriptionVisible && (
                        <div>
                            <Subscription />
                        </div>
                    )}

                    {isChangePasswordVisible && (
                        <div>
                            <ChangePassword />
                        </div>
                    )}
                </div>
            </div>

            <EditProfileModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
};

export default Settings;
