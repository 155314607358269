import React, { useEffect, useRef, useState } from 'react';

const TestPage = ({ onMsisdnFetched }) => {
    const [responseData, setResponseData] = useState('');
    const [loading, setLoading] = useState(false);
    const fetchRef = useRef(false);

    useEffect(() => {
        if (fetchRef.current) return;
        fetchRef.current = true;

        const getAccessToken = async () => {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("Authorization", "Basic MmRzaG42OWVvb29xOWNhcWY4dHVjOXZnMTg6MWhkMzNsMWFzNDllbnY0b2MzdHE4dXVoZ25lbXBjZGJjb3I3ZDVsZTRhdG92NTllNmRucg==");
            myHeaders.append("Cookie", "XSRF-TOKEN=517b443c-996c-4b96-8bb8-7682ea3540ec; XSRF-TOKEN=40172a47-ca5b-4f7a-8705-869c5d22bfd4");

            const urlencoded = new URLSearchParams();

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };

            try {
                console.log("Request Sent for Access Token:", requestOptions);
                const response = await fetch("https://third-party.auth.eu-west-1.amazoncognito.com/oauth2/token?grant_type=client_credentials", requestOptions);
                const result = await response.json();
                console.log("Server Response for Access Token:", result);
                return result.access_token;
            } catch (error) {
                console.error("Error fetching access token:", error);
            }
        };

        const fetchDataWithAccessToken = async (token) => {
            const myHeaders = new Headers();
            myHeaders.append("Cookie", "visid_incap_2516540=i5JFH0s9RdSU5IBjlIgHvxpsmmYAAAAAQUIPAAAAAAA7p7Y3y/hPj4qgStiePgHu; nlbi_2516540=OnTgGqRngW766Z7EFnrzdAAAAACPwrto8KxaAh1xWdYR+5Ic; incap_ses_1023_2516540=CiJfItaKeyP2SrLNMm0yDhpsmmYAAAAAzpQSOK/mJbwPBnfDbTgabw==; incap_ses_1346_2516540=yvPdQz4hCF9eER229fOtEh+3m2YAAAAASfUuDHf2KZrzNOUZcX+hvA==; visid_incap_2516540=MRgXXx/jQ+qOWQw+nzWxJ5xGmmYAAAAAQUIPAAAAAADVRugbOvL8JBfxNrVZV+nQ");
            myHeaders.append("Content-Type", "Application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("X-Source-System", "he-partner");
            myHeaders.append("X-App", "he-partner");
            myHeaders.append("X-MessageID", "1234");

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            try {
                console.log("Request Sent for Fetch Data:", requestOptions, "with Access Token:", token);
                const response = await fetch("https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn", requestOptions);
                const result = await response.json(); // Update this line to parse JSON
                console.log("Server Response for Fetch Data:", result);
                setResponseData(result);
                
                // Extract MSISDN
                const msisdn = result?.ServiceResponse?.ResponseBody?.Response?.Msisdn || '';
                console.log("MSISDN fetched:", msisdn);
                if (onMsisdnFetched) {
                    onMsisdnFetched(msisdn); // Pass MSISDN to the parent component
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        const fetchData = async () => {
            setLoading(true);
            const token = await getAccessToken();
            if (token) {
                await fetchDataWithAccessToken(token);
            }
        };

        fetchData();
    }, [onMsisdnFetched]);

    return (
        <div className='w-full'>
        </div>
    );
};

export default TestPage;
