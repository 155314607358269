import Image from '../../images/Ellipse 1402.png'

const data = [
    {
        Description: "This health portal simplifies navigating health information with well-organized, scientifically-backed data. The user-friendly interface and top-notch resources help users make informed decisions, making it a reliable go-to for enhancing health literacy.",  
        Name: "Catherine Ezegwu",
        Job: "Owner, Cathy’s place",
        Image: Image // Assign Catherine's image
    },
    {
        Description: "This portal excels with its comprehensive, up-to-date health content. Suitable for both professionals and laypeople, it offers valuable insights and practical advice. The clear articles and intuitive layout ensure quick access to trustworthy information. Highly recommended for reliable health resources.",  
        Name: "Harrison Akintola",
        Job: "Business Man",
        Image: Image // Assign Harrison's image
    },
    {
        Description: "I've been a patient at this healthcare clinic for years, and I continue to be impressed by the level of professionalism and expertise displayed by the staff. Whether it's a routine check-up or a more serious medical issue, I always feel like I'm in good hands here. Thank you for providing such top-notch care!",  
        Name: "Njika Godwin",
        Job: "Software Engineer",
        Image: Image // Assign Njika's image
    },
    {
        Description: "I am continually impressed by the quality of care provided by the healthcare professionals at this facility. They always take the time to explain my treatment options and involve me in the decision-making process. Thank you for your dedication to patient-centered care.",  
        Name: "Akinbola Rukayat",
        Job: "Economist",
        Image: Image // Assign Rukayat's image
    }
];

export default data;
