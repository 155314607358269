import React from 'react'
import Navbar from '../component/landing/Navbar'
import Footer from '../component/landing/Footer'

const Terms = () => {
    return (
        <div className='w-full h-fit bg-white'>
            <div>
                <Navbar />
            </div>
            
            
            <div className='p-6 tracking-wider flex flex-col gap-3
            lg:px-16'>
                <p className='font-bold text-xl'>
                QuickCare Terms and Conditions
                </p>

                <p className='font-medium'>
                Welcome to QuickCare, provided by Centurion Technologies ("Provider"). These Terms and
                Conditions govern your use of QuickCare, an innovative health information platform integrated
                with the Safaricom network in Kenya. By accessing or using QuickCare, you agree to be bound by
                these Terms and Conditions. If you do not agree with any part of these Terms and Conditions,
                you must not use QuickCare
                </p>

                <p className='font-bold text-xl'>
                1. Service Description:
                </p>

                <p className='font-medium'>
                QuickCare is a web-based chat application and health information portal designed to provide
                immediate medical tips and general health-related information. The service includes virtual
                consultations with ChatBots, health education, e-booking for diagnostics, electronic records,
                emergency care, and daily health tips.
                </p>

                <p className='font-bold text-xl'>
                2. Scope of Service:
                </p>

                <p className='font-medium'>
                QuickCare does not provide personalized medical diagnostics, treatment advice, or laboratory
                testing facilities. The service aims to empower users with valuable health insights to make
                informed decisions about their well-being. Users should not substitute QuickCare for professional
                medical advice, diagnosis, or treatment.
                </p>

                <p className='font-bold text-xl'>
                3. Accessibility and Availability:
                </p>

                <p className='font-medium'>
                QuickCare aims to be accessible anytime, anywhere. However, the availability of the service may
                be subject to limitations or interruptions due to maintenance, upgrades, or unforeseen
                circumstances. We do not guarantee uninterrupted access to QuickCare
                </p>

                <p className='font-bold text-xl'>
                4. User Responsibilities:
                </p>

                <p className='font-medium'>
                Users are responsible for ensuring the accuracy and completeness of the information provided
                during consultations or interactions with QuickCare. Users should not disclose sensitive personal
                information or rely solely on QuickCare for emergency situations.
                </p>

                <p className='font-bold text-xl'>
                5. Health Consultations:
                </p>

                <p className='font-medium'>
                QuickCare offers virtual consultations with ChatBots, providing general health information and
                tips. These consultations do not constitute personalized medical advice or diagnosis. Users
                should consult a qualified healthcare professional for personalized medical assistance.
                </p>

                <p className='font-bold text-xl'>
                6. Subscription Service:
                </p>

                <p className='font-medium'>
                QuickCare offers a subscription service where users can receive daily health tips and a 30-minute
                health consultation with our ChatBot by sending Keywords to the provided shortcode. Standard
                messaging rates may apply, and users must have a compatible mobile device and active
                Safaricom network connection
                </p>

                <p className='font-bold text-xl'>
                7. Intellectual Property:
                </p>

                <p className='font-medium'>
                All content, trademarks, logos, and intellectual property rights associated with QuickCare belong
                to the Provider. Users are granted a limited, non-exclusive, non-transferable license to access
                and use QuickCare for personal, non-commercial purposes only
                </p>

                <p className='font-bold text-xl'>
                8. Privacy and Data Security:
                </p>

                <p className='font-medium'>
                QuickCare respects user privacy and complies with applicable data protection laws. User data
                collected during interactions with QuickCare will be handled in accordance with our Privacy
                Policy. By using QuickCare, you consent to the collection, use, and disclosure of your personal
                information as described in the Privacy Policy.
                </p>

                <p className='font-bold text-xl'>
                9. Limitation of Liability:
                </p>

                <p className='font-medium'>
                In no event shall the Provider be liable for any direct, indirect, incidental, special, or
                consequential damages arising out of or in any way connected with the use or inability to use
                QuickCare, including but not limited to damages for loss of profits, data, or goodwill.
                </p>

                <p className='font-bold text-xl'>
                10. Modification of Terms:
                </p>

                <p className='font-medium'>
                The Provider reserves the right to modify, suspend, or terminate QuickCare or these Terms and
                Conditions at any time without prior notice. Continued use of QuickCare after any such changes
                constitutes acceptance of the modified Terms and Conditions.
                </p>

                <p className='font-bold text-xl'>
                11. Governing Law:
                </p>

                <p className='font-medium'>
                These Terms and Conditions shall be governed by and construed in accordance with the laws of
                Kenya. Any dispute arising out of or relating to these Terms and Conditions or the use of
                QuickCare shall be subject to the exclusive jurisdiction of the courts of Kenya.
                </p>

                <p className='font-medium'>
                If you have any questions or concerns about these Terms and Conditions, please contact us at <br />
                Address: Royal Oak. flat 5A Lavington Nairobi. <br />
                Phone: +254 794 867517. 
                </p>

                

            </div>

            <div className='mt-6 w-full '>
            <Footer />
            </div>
        </div>
    )
}

export default Terms
