import React from 'react'
import Logo from "../../images/logo/Quick-Care.png";
import Chat from '../../images/Group 1000002507.png'
import { Link } from 'react-router-dom'
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";



const Footer = () => {
    return (
        <div className='mt-20 bg-white text-black shadow-2xl  shadow-[#069125]
        p-4
        md:px-8 lg:px-16
         flex flex-col relative  gap-6 md:p-8 w-full md:mt-48 lg:mt-52'>
            <div className='w-full flex flex-col gap-5 md:flex-row items-start md:justify-between lg:gap-16'>
                <div className='flex flex-col gap-3  text-sm md:w-1/3'>
                    <img src={Logo} alt="" className='w-[150px] lg:w-[200px] h-[auto]'/> 

                    <p className='text-base font-medium'>
                    Quick Care is your go-to online platform for swift health solutions. 
                    With a user-friendly interface, find instant remedies for common health queries. 
                    From symptoms to treatments, get concise information tailored to your needs. 
                    Your health matters, and Quick Care makes it easier to stay informed and make informed decisions.
                    </p>
                </div> 

                <div className='flex flex-col gap-3 md:w-1/3'>
                    <p className='text-xl font-semibold'>
                   Terms and Policies
                    </p>

                        <Link to='/terms&conditions' className='font-medium'>
                        Terms & Conditions
                        </Link>

                        <Link to='/privacy&policies' className='font-medium'>
                        Privacy & Policies
                        </Link>
                </div>

            </div>
           

          <div className='flex items-center gap-3 text-2xl'>
                <FaFacebook />
                <FaXTwitter />
                <FaInstagram />
                <FaLinkedin />
          </div>

            <img src={Chat} alt="" className='w-20 h-20 absolute bottom-3 right-5
            md:bottom-3 md:right-16'/>
        </div>
    )
}

export default Footer
