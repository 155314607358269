const data = [
    {
      Plan: "Basic Plan",  
      Price: "12 Ksh daily",
      Feature: [
      "Access to basic health care resources", 
      "Access to text based consultation", 
    ]
    },
    {
      Plan: "Silver Plan (coming soon)",
      Price: "60 Ksh monthly",
      Feature: [
      "Access to basic health care resources", 
      "Access to text based consultation", 
      "Access to SMS based consultations", 
    ]
    },
    {
      Plan: "Premium Plan (coming soon)",
      Price: "135 Ksh monthly",
      Feature: [
      "Access to basic health care resources", 
      "Access to text based consultation ", 
      "Access to SMS based consultations", 
      "⁠Access to call based consultations", 
    ]
    },
    
  ];
  
  export default data;
  