// marketers.js
let marketerData = {};

// Save marketer info to localStorage
export const saveMarketerInfo = (trxid, productId) => {
    localStorage.setItem('marketerInfo', JSON.stringify({ trxid, productId }));
    console.log('Marketer Info saved:', { trxid, productId });
};

// Retrieve marketer info from localStorage
export const getMarketerInfo = () => {
    const savedMarketerInfo = localStorage.getItem('marketerInfo');
    if (savedMarketerInfo) {
        marketerData = JSON.parse(savedMarketerInfo);
    }
    return marketerData;
};
