
const data = [
    {
      Description : "Lab test",  
      Date: "05/06/2024",
      Amount: "18,000.00",
     
    },
    {
        Description : "Consultation fee",
        Date: "05/06/2024",
        Amount: "25,000.00",
       
    },
    {
        Description : "Medication Charges",  
        Date: "05/06/2024",
        Amount: "620,000.00",
       
    },
    {
        Description : "Consultation fee",
        Date: "05/06/2024",
        Amount: "25,000.00",
       
    },
    {
        Description : "Refund", 
        Date: "05/06/2024",
        Amount: "25,000.00",
       
    },
];

export default data;
