import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../utils/tokenHelper';
import { saveSymptoms, getSymptoms } from '../utils/symptomHelper';

const SymptomForm = () => {
  const [formData, setFormData] = useState({
    symptoms: '',
    symptomStart: '',
    chronicConditions: '',
    allergies: ''
  });

  const [responseMessage, setResponseMessage] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = [
      { question: 'What are your symptoms?', answer: formData.symptoms },
      { question: 'When did your symptoms start?', answer: formData.symptomStart },
      { question: 'Do you have any chronic conditions or previous major illnesses?', answer: formData.chronicConditions },
      { question: 'Do you have any allergies?', answer: formData.allergies }
    ];

    console.log('Data sent to the server:', payload);

    // Save only the symptoms to localStorage
    saveSymptoms(formData.symptoms);

    // Log the saved symptoms
    const savedSymptoms = getSymptoms();
    console.log('Saved Symptoms:', savedSymptoms);

    const token = getToken();
    console.log('Retrieved token:', token);  // Log the retrieved token
    if (!token) {
      console.error('No token found');
      setResponseMessage('No token found');
      return;
    }

    try {
      const response = await fetch('https://api.quickcare.ng/api/v1/ai/introduction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ payload })
      });

      const data = await response.json();
      console.log('Response from the server:', data);

      if (response.ok) {
        setResponseMessage('Success: ' + data.response);
        setTimeout(() => {
          navigate('/consultation');
        }, 2000);
      } else {
        setResponseMessage('Error: ' + data.response);
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('Error: ' + error.message);
    }
  };

  return (
    <div>
      {responseMessage && (
        <div className='response-message text-[#069125] w-full text-center pb-3 font-semibold'>
          {responseMessage}
        </div>
      )}
      <form onSubmit={handleSubmit} className='flex flex-col gap-4 w-full tracking-wider'>
        <div className='flex flex-col gap-2 w-full'>
          <label className='font-semibold text-[#292929] text-lg'>What are your symptoms?</label>
          <input
            type="text"
            name="symptoms"
            value={formData.symptoms}
            onChange={handleChange}
            required
            placeholder='E.g (High Temperature)'
            className='p-2 border-[#292929] bg-white text-[#292929] outline-0 border rounded-md'
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <label className='font-semibold text-[#292929] text-lg'>When did your symptoms start?</label>
          <input
            type="text"
            name="symptomStart"
            value={formData.symptomStart}
            onChange={handleChange}
            required
            placeholder='E.g (3 days ago)'
            className='p-2 border-[#292929] bg-white text-[#292929] outline-0 border rounded-md'
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <label className='font-semibold text-[#292929] text-lg'>Do you have any chronic conditions or previous major illnesses?</label>
          <input
            type="text"
            name="chronicConditions"
            value={formData.chronicConditions}
            onChange={handleChange}
            required
            placeholder='If yes be sure to add a condition'
            className='p-2 border-[#292929] bg-white text-[#292929] outline-0 border rounded-md'
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <label className='font-semibold text-[#292929] text-lg'>Do you have any allergies?</label>
          <input
            type="text"
            name="allergies"
            value={formData.allergies}
            onChange={handleChange}
            required
            placeholder='If yes be sure to add your allergies'
            className='p-2 border-[#292929] bg-white text-[#292929] outline-0 border rounded-md'
          />
        </div>
        <button type="submit" className='w-1/2 bg-[#069125] text-white  font-medium rounded p-2 flex items-center justify-center uppercase '>
          Submit
        </button>
      </form>
    </div>
  );
};

export default SymptomForm;
