import React, { useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import data from "../data/testimonials";

const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentIndex(prevIndex => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentIndex(prevIndex => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className='w-full flex flex-col gap-4 p-4
        md:px-8 lg:px-16'>
           <div className='w-full flex items-center justify-between'>
            <p>
                What Our Clients Say
            </p>

            <div className='flex items-center gap-4'>
                <button className='border border-[#069125] text-[#069125] rounded-full p-2 w-8 h-8 flex items-center justify-center' onClick={handlePrevClick}>
                    <KeyboardArrowLeftIcon />
                </button>

                <button className='bg-[#069125] text-white rounded-full p-2 w-8 h-8 flex items-center justify-center' onClick={handleNextClick}>
                    <KeyboardArrowRightIcon />
                </button>
            </div>
           </div> 

           <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2'>
                {[currentIndex, (currentIndex + 1) % data.length].map(index => (
                    <div key={index} className='bg-white  rounded-lg flex flex-col items-start'>
                        <div className='w-full bg-[#308042] p-3 py-5 rounded-lg flex flex-col gap-2 text-white h-[270px] items-start justify-between'>
                            <p className='font-semibold'>
                                {data[index].Description}
                            </p>
                            <div className='flex items-center gap-1'>
                                <img src={data[index].Image} alt="" />
                                <div>
                                    <p className='text-sm'>
                                        {data[index].Name} 
                                    </p>
                                    <p className='text-xs'>
                                        {data[index].Job} 
                                    </p>
                                </div>  
                            </div>
                        </div>
                    </div>
                ))}
           </div>
        </div>
    );
}

export default Testimonials;
