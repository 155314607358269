import React, { useState } from 'react';
import Stetoscope from '../images/stetoscope.png';
import Logo from '../images/logo.png';
import { Link, useNavigate } from 'react-router-dom';

const Signup = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '254',  // Set initial phone state to '234'
        password: '',
        date_of_birth: '',  // New field for date of birth
        gender: '',  // New field for gender
    });

    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const navigate = useNavigate();  // Initialize useNavigate

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Ensure phone number always starts with '234' and user can type the rest
        if (name === 'phone') {
            if (value.startsWith('254')) {
                setFormData({ ...formData, [name]: value });
            } else {
                setFormData({ ...formData, [name]: '254' + value.slice(3) });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Client-side validation
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.password || !formData.date_of_birth || !formData.gender) {
            setMessage('All fields are required.');
            setIsError(true);
            return;
        }

        const requestData = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            phone_number: formData.phone,
            email: formData.email,
            password: formData.password,
            date_of_birth: formData.date_of_birth,  // Include date of birth in request
            gender: formData.gender,  // Include gender in request
        };

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(requestData),
            redirect: "follow"
        };

        try {
            const response = await fetch("https://api.quickcare.ng/api/v1/user/signup", requestOptions);
            if (response.ok) {
                const result = await response.json();
                setMessage('Signup successful!');
                setIsError(false);
                console.log('Signup successful:', result);
                navigate('/login');  // Redirect to login page after successful signup
            } else {
                setMessage('Signup failed. Please try again.');
                setIsError(true);
                console.error('Signup failed:', response.statusText);
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
            setIsError(true);
            console.error('Error:', error);
        }
    };

    return (
        <div className='w-full md:flex flex-row h-screen'>
            <div className='w-full flex flex-col gap-4 p-7 md:w-1/2 md:items-start md:justify-center md:gap-16 lg:gap-2'>
                <Link to='/'>
                    <img src={Logo} alt="" className='w-[150px] h-[30px]'/>
                </Link>

                <div className='mt-4 flex w-full flex-col gap-2'>
                    <h1 className='text-2xl font-semibold'>Signup</h1>

                    <div className='flex flex-row gap-6'>
                        <p>Already have an account?</p>
                        <Link to='/login' className='text-[#45B139]'>Login</Link>
                    </div>
                </div>

                {message && (
                    <div className={`mt-4 p-2 text-center ${isError ? 'text-red-500' : 'text-green-500'}`}>
                        {message}
                    </div>
                )}

                <form onSubmit={handleSubmit} className='flex flex-col w-full gap-4 items-center md:gap-8 lg:gap-6'>
                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='First Name'
                            required
                        />
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Last Name'
                            required
                        />
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Email Address'
                            required
                        />
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Phone No'
                            required
                        />
                    </div>
                    
                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="date"
                            name="date_of_birth"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Date of Birth'
                            required
                        />
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            required
                        >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </div>

                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Password'
                            required
                        />
                    </div>
                    <button type='submit' className='w-full flex items-center justify-center p-2 rounded bg-[#069125] font-medium text-white tracking-wider'>
                        Signup
                    </button>
                </form>
            </div>
            <img src={Stetoscope} alt="" className='hidden md:flex w-1/2'/>
        </div>
    );
};

export default Signup;
