
const data = [
    {
      Type: "White Blood Cell Count (WBC)",  
      Date: "05/06/2024",
      Cost: "18,000.00",
     
    },
    {
        Type: "Red Blood Cell Count (RBC)",  
        Date: "05/06/2024",
        Cost: "25,000.00",
       
    },
    {
        Type: "Hemoglobin (Hgb)",  
        Date: "05/06/2024",
        Cost: "620,000.00",
       
    },
    {
        Type: "Hematocrit (Hct)",  
        Date: "05/06/2024",
        Cost: "25,000.00",
       
    },
    {
        Type: "Platelet Count",  
        Date: "05/06/2024",
        Cost: "25,000.00",
       
    },
    {
        Type: "Mean Corpuscular Hemoglobin",  
        Date: "05/06/2024",
        Cost: "25,000.00",
       
    },
    {
        Type: "Mean Corpuscular Volume (MCV)",  
        Date: "05/06/2024",
        Cost: "25,000.00",
       
    },

];

export default data;
