import React, { useState } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

const Insurance = () => {
    const [isContentVisible, setIsContentVisible] = useState(false);

    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };

    const [isContentVisible2, setIsContentVisible2] = useState(false);

    const toggleContentVisibility2 = () => {
        setIsContentVisible2(!isContentVisible2);
    };

    const [isContentVisible3, setIsContentVisible3] = useState(false);

    const toggleContentVisibility3 = () => {
        setIsContentVisible3(!isContentVisible3);
    };

    const [isContentVisible4, setIsContentVisible4] = useState(false);

    const toggleContentVisibility4 = () => {
        setIsContentVisible4(!isContentVisible4);
    };

    return (
        <div className='full mt-4 flex flex-col gap-3
        lg:w-2/3'>
            <div className='w-full border border-gray-300 p-3 rounded-lg bg-white flex flex-col gap-2 items-start'>
                <p className='font-semibold'>
                Quickcare Insurance Services
                </p>

                <p className='text-sm'>
                Welcome to the Insurance Coverage section of Quickcare. 
                We understand that navigating insurance can be complex, 
                so we're here to simplify the process for you. 
                Our goal is to ensure that you have access to 
                the care you need without any unnecessary hassle.
                </p>

                <button className='bg-[#069125] text-white font-meduim rounded-lg py-2 px-3 mt-3'>
                Get Insured
                </button>
            </div>

            <div className='w-full border border-gray-300 p-3 rounded-lg bg-white flex flex-col gap-2 items-start'>
                <p className='font-semibold'>
                Services Covered
                </p>

                <p className='text-sm'>
                We work with a wide range of insurance 
                providers to ensure that our patients have access 
                to the care they need. Our services typically include:
                </p>

                <ul className='w-full flex flex-col gap-1 list-disc ml-6 
                md:grid md:grid-cols-2 md:gap-2'>
                    <li>
                        Primary Care Consultations
                    </li>

                    <li>
                        Specialist Referrals
                    </li>

                    <li>
                        Diagnostic Testing
                    </li>

                    <li>
                        Preventive Care
                    </li>

                    <li>
                        Vaccinations and Immunizations
                    </li>

                    <li>
                        Prescription Medications
                    </li>

                    <li>
                        Chronic Disease Management
                    </li>

                    <li>
                        Mental Health Services
                    </li>
                </ul>
            </div>

            <div className='w-full border border-gray-300 p-3 rounded-lg bg-white flex flex-col gap-2 items-start'>
                <p className='font-semibold'>
                Accepted Insurance Providers
                </p>

                <ul className='flex flex-col gap-1 list-disc ml-6'>
                    <li>
                    Medicare
                    </li>

                    <li>
                    Medicaid
                    </li>

                    <li>
                    Private Insurance (Aetna, Cigna, UnitedHealthcare, etc.)
                    </li>

                    <li>
                    Employer-Sponsored Plans
                    </li>

                    <li>
                    Affordable Care Act (ACA) Plans
                    </li>

                    <li>
                    Tricare
                    </li>
                </ul>
            </div>

            <p>
            If you don't see your insurance provider listed here, 
            please don't hesitate to contact us. We're constantly updating our 
            list of accepted plans and may be able to accommodate your needs.
            </p>

            <div className='flex flex-col w-full gap-3'>
                <p className='font-semibold'>
                FAQs
                </p>

                <div className='flex flex-col gap-3'> 
                    <div className='w-full border border-gray-300 p-3 rounded-lg bg-white flex flex-col gap-2 items-start'>
                        <div className='flex w-full items-center justify-between'> 
                            <p className='font-semibold'>
                            Q: What if I don't have insurance? 
                            </p>

                            <button onClick={toggleContentVisibility} className='text-[#069125]'>
                                {isContentVisible ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
                            </button>
                        </div>
                    
                        {isContentVisible && (
                            <p>
                                A: We offer flexible payment options for patients without 
                                insurance coverage. Our team can work with you to develop a payment plan that fits your budget.
                            </p>
                        )}
                    </div>

                    <div className='w-full border border-gray-300 p-3 rounded-lg bg-white flex flex-col gap-2 items-start'>
                        <div className='flex w-full items-center justify-between'> 
                            <p className='font-semibold'>
                            Q: Will I need to pay anything at the time of my appointment? 
                            </p>

                            <button onClick={toggleContentVisibility2} className='text-[#069125]'>
                                {isContentVisible2 ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
                            </button>
                        </div>
                    
                        {isContentVisible2 && (
                            <p>
                               A: Any required copayments or deductibles will be collected at the time of your appointment. 
                               We'll provide you with a detailed breakdown of any costs before your visit.
                            </p>
                        )}
                    </div>

                    <div className='w-full border border-gray-300 p-3 rounded-lg bg-white flex flex-col gap-2 items-start'>
                        <div className='flex w-full items-center justify-between'> 
                            <p className='font-semibold'>
                            Q: How can I verify my insurance coverage? 
                            </p>

                            <button onClick={toggleContentVisibility3} className='text-[#069125]'>
                                {isContentVisible3 ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
                            </button>
                        </div>
                    
                        {isContentVisible3 && (
                            <p>
                                A: Simply provide us with your insurance information when you schedule your appointment, 
                                and our team will handle the rest.
                            </p>
                        )}
                    </div>

                    <div className='w-full border border-gray-300 p-3 rounded-lg bg-white flex flex-col gap-2 items-start'>
                        <div className='flex w-full items-center justify-between'> 
                            <p className='font-semibold'>
                            Q: What if my insurance claim is denied? 
                            </p>

                            <button onClick={toggleContentVisibility4} className='text-[#069125]'>
                                {isContentVisible4 ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
                            </button>
                        </div>
                    
                        {isContentVisible4 && (
                            <p>
                               A: If your insurance claim is denied, 
                               we'll work with you to appeal the decision 
                               and explore alternative options for coverage.
                            </p>
                        )}
                    </div>

                </div>
            </div>

            <p>
            If you have any questions about insurance coverage or would 
            like to schedule an appointment, please don't hesitate to contact our friendly team. We're here 
            to help you get the care you need, when you need it.
            </p>
        </div>
    )
}

export default Insurance
