import React, { useState } from 'react';
import Header from '../component/Header';
import Sidebar from '../component/Sidebar';
import data from '../component/data/testresult';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';



const LabResult = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    

    return (
        <div className='w-full bg-[#F9FAFB]'>
            <div className='fixed w-full'>
                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  
            </div>

            <div className={`pt-16 md:pt-20 w-full ${isSidebarOpen ? 'md:pt-20 md:w-3/4 lg:w-4/5' : 'md:w-3/4 lg:w-4/5 xl:w-5/6'}`}>
                <div className={`${isSidebarOpen ? 'md:block z-50' : 'hidden md:block z-50'}`}>
                    <Sidebar />
                </div>

                <div className='pb-96 md:ml-48 p-6 xl:ml-60  w-full flex flex-col gap-3
                 mt-4 '>
                    <p className='font-semibold'>
                    Hello Isaac
                    </p>

                    <p>
                    At Quickcare, we prioritize transparency and timely access to your health information. 
                    Your lab results are a critical aspect of understanding your health status and guiding your 
                    treatment plan. Below, you'll find detailed information about your recent lab tests:
                    </p>

                    <div className='w-full flex flex-col gap-3 border border-gray-300 p-3 rounded-lg bg-white'>
                        <p className='font-semibold'>
                        Patient’s Information:
                        </p>

                        <div className='flex flex-col gap-1'>
                            <div className='flex items-center gap-2'>
                                <p className='text-[#646464]'>
                                Name:
                                </p>

                                <p>
                                Isaac Ugbeda
                                </p>
                            </div>

                            <div className='flex items-center gap-2'>
                                <p className='text-[#646464]'>
                                Date of Birth:
                                </p>

                                <p>
                                25 August 2001
                                </p>
                            </div>

                            <div className='flex items-center gap-2'>
                                <p className='text-[#646464]'>
                                Age:
                                </p>

                                <p>
                                24yrs
                                </p>
                            </div>

                            <div className='flex items-center gap-2'>
                                <p className='text-[#646464]'>
                                Gender:
                                </p>

                                <p>
                                Male
                                </p>
                            </div>

                            <div className='flex items-center gap-2'>
                                <p className='text-[#646464]'>
                                Phone Number:
                                </p>

                                <p>
                                07086339078
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className='w-full flex flex-col gap-3 bg-white border border-gray-300 rounded-lg text-xs mt-2
                    md:text-base'>
                        <div className='w-full flex items-center justify-between p-2 bg-[#EEF1F8] font-semibold'>
                            <p className='w-1/4'>
                            Test Type
                            </p>

                            <p className='w-1/4'>
                            Date
                            </p>

                            <p className='w-1/4'>
                            Cost
                            </p>

                            <p className='w-1/4'>
                            Download
                            </p>
                        </div>
                        
                        <div className='w-full flex flex-col gap-2 p-2'>
                            {data.map((item, index) => (
                                <div key={index} className='w-full flex items-start justify-between text-[10px] font-medium 
                                md:text-base'>
                                    <p className='w-1/4'>
                                    {item.Type}
                                    </p>
                                    <p className='w-1/4'>
                                    {item.Date}
                                    </p>
                                    <p className='w-1/4'>
                                    N{item.Cost}
                                    </p>
                                    <button className='w-1/4 flex items-center gap-1 font-medium'>
                                        <span className='underline'>
                                        Get Result 
                                        </span>

                                        <span className='text-[#069125]'>
                                        <SimCardDownloadOutlinedIcon />
                                        </span>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <button className='bg-[#069125] text-white font-medium w-1/2 mt-3 py-2 px-3 rounded-lg
                    lg:w-1/6'>
                        Talk to a Doctor
                    </button>

                  
                </div>
            </div>
        </div>
    );
};

export default LabResult;
