import React, { useState } from 'react';
import { getToken } from '../utils/tokenHelper';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState('');

    const handleChangePassword = () => {
        const token = getToken();
        console.log('Retrieved token:', token);  // Log the retrieved token
        if (!token) {
            console.error('No token found');
            setResponse('Error: No token found');
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                current_password: currentPassword,
                new_password: newPassword
            }),
            redirect: "follow"
        };

        fetch("https://api.quickcare.ng/api/v1/user/profile/change-password", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("Data sent:", {
                    current_password: currentPassword,
                    new_password: newPassword
                });
                console.log("Result:", result);
                setResult(result);
                if (result.success) { // Assuming the API response contains a success field
                    setResponse('Password changed successfully');
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    setResponse('Incorrect Password');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error);
                setResponse('Error: ' + error.message);
            });
    };

    return (
        <div className='w-full mt-4 flex flex-col gap-4 pb-[1000px]'>
            {response && <p className='text-green-500 font-medium'>{response}</p>}
            <div className='flex flex-col gap-2'>
                <label htmlFor="currentPassword">Current Password:</label>
                <input
                    type="password"
                    id="currentPassword"
                    value={currentPassword}
                    onChange={e => setCurrentPassword(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded outline-0 p-2"
                />
            </div>
            <div className='flex flex-col gap-2'>
                <label htmlFor="newPassword">New Password:</label>
                <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded outline-0 p-2"
                />
            </div>
            <button onClick={handleChangePassword} className="bg-[#069125] text-white font-medium py-2 px-4 rounded mt-3 lg:w-1/4">
                Change Password
            </button>
            
        </div>
    );
};

export default ChangePassword;
