import React, { useEffect } from 'react'; 
import Navbar from '../component/landing/Navbar';
import Hero from '../component/landing/Hero';
import About from '../component/landing/About';
// import Service from '../component/landing/Service';
import Benefit from '../component/landing/Benefit';
import Testimonials from '../component/landing/Testimonials';
import ContactUs from '../component/landing/ContactUs';
import Patners from '../component/landing/Patners';
import Footer from '../component/landing/Footer';
import { getMarketerInfo } from '../utils/marketers'; // Import the function

const LandingPage = () => {
    
    useEffect(() => {
        // Get URL parameters
        const searchParams = new URLSearchParams(window.location.search);
        const requestId = searchParams.get('requestid');
        const msisdn = searchParams.get('msisdn');
        const message = searchParams.get('message');

        // Log the extracted values for debugging
        console.log('Request ID:', requestId);
        console.log('MSISDN:', msisdn);
        console.log('Message:', message);

        // Get marketer info from localStorage
        const marketerInfo = getMarketerInfo();
        console.log('TRX ID:', marketerInfo.trxid);
        console.log('Product ID:', marketerInfo.productId);

        // Determine the activation and description based on the message content
        const isSuccess = message?.includes("Thank you customer");
        const activation = isSuccess ? "1" : "0";  // Sent as string
        const description = isSuccess ? "success" : "failed";

        // Get the current timestamp in the format YYYYMMDDHHmm
        const currentTimestamp = new Date().toISOString().slice(0, 16).replace(/[-T:]/g, '');

        // Prepare the data for the POST request
        const requestData = {
            msisdn: msisdn || 'N/A',
            activation: activation,  // Sent as string
            product_id: marketerInfo.productId || 'N/A',
            description: description,
            timestamp: currentTimestamp,
            trxId: marketerInfo.trxid || 'N/A',
        };

        console.log('Data to be sent:', requestData);

        // Define the POST request headers and body
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(requestData),
            redirect: "follow",
        };

        // Send the POST request
        fetch("https://api.quickcare.ng/api/v1/marketing/kenya/notification", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log('Server response:', result);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        
    }, []);

    return (
        <div className='w-full h-fit bg-white'>
            <div>
                <Navbar />
            </div>

            <div className='mt-6 w-full '>
                <Hero />
            </div>

            <div className='mt-6 w-full ' id='about-us'>
                <About />
            </div>

            {/* <div className='mt-6 w-full ' id='services'>
                <Service />
            </div> */}

            <div className='mt-6 w-full ' id='benefits'>
                <Benefit />
            </div>

            <div className='mt-6 w-full '>
                <Testimonials />
            </div>

            <div className='mt-6 w-full '>
                <ContactUs />
            </div>

            <div className='mt-6 w-full '>
                <Patners />
            </div>

            <div className='mt-6 w-full '>
                <Footer />
            </div>
        </div>
    );
}

export default LandingPage;
