import profile from "../../images/Doctor-1.png";
import profile2 from "../../images/Doctor-2.png";
import profile3 from "../../images/Doctor-3.png";
import profile4 from "../../images/Doctor-4.png";

const data = [
    {
      Name: "Dr. Suleiman Dogo",  
      Title: "Family Physician:",
      Message: "Provides primary care for the whole family, including children, adults, and seniors. They perform routine checkups, screenings, vaccinations, and manage chronic conditions.",
      profileImage: profile
    },
    {
      Name: "Dr. Moses Maikudi",  
      Title: "Dermatologist:",
      Message: "Treats conditions of the skin, hair, and nails. This includes concerns like moles, scars, acne, and skin allergies.",
      profileImage: profile2
    },
    {
      Name: "Dr. Amina Issa",  
      Title: "Mental Health Professional",
      Message: "Works with patients who have various mental illnesses such as depression, anger management problems and suicidal thoughts.",
      profileImage: profile3
    },
    {
      Name: "Dr. Uchenna Wisdom",  
      Title: "Neurosurgeon",
      Message: "A surgeon who operates on the brain and spinal cord. They treat conditions like brain tumors, aneurysms, and spinal injuries.",
      profileImage: profile4
    },
];

export default data;
