import React, { useState } from 'react';
import Logo from "../../images/logo/Quick-Care.png";
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className='w-full flex items-center justify-between p-4
        md:px-8 lg:px-16'>
            <Link to='/'>
            <img src={Logo} alt="" className='w-[150px] lg:w-[200px] h-[auto] mt-2 md:mt-0'/> 
            </Link>
           
            <div className={`lg:flex gap-10 items-center font-medium ${isMenuOpen ? 'absolute flex py-4 flex-col gap-1 top-20 left-0 w-full bg-[#069125] text-white' 
            : 'hidden'}`}>
                <a href='#services'>
                    Services
                </a>

                <a href='#benefits'>
                    Benefits
                </a>

                <a href='#about-us'>
                    About Us
                </a>

                <Link to='/subscription' className=''>
                    Pay with Airtime
                </Link>

                <Link to='/login' className=''>
                    Login
                </Link>
            </div>

            <div className='hidden lg:flex gap-3 lg:ml-4'>
                <Link to='/subscription' className='bg-[#069125] text-white font-medium px-3 py-2 rounded-lg'>
                    Pay with Airtime
                </Link>

                <Link to='/login' className='border-2 border-[#069125] text-[#069125] font-medium px-6 py-2 rounded-lg'>
                    Login
                </Link>
            </div>

           

            <div className='lg:hidden p-1 border border-black rounded' onClick={toggleMenu}>
                {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </div>
        </div>
    );
};

export default Navbar;
