import React from 'react'
import data from "../component/data/payment"

const Payment = () => {
    return (
        <div className='w-full mt-4 flex flex-col gap-4 items-start pb-96'>
            <p className='font-semibold'>
                Recent Transactions
            </p>

            <div className='w-full flex flex-col gap-2 border border-gray-300 rounded-lg bg-white'>
                <div className='w-full p-2 flex items-center justify-between bg-gray-300 font-medium rounded-t-lg'>
                    <p className='w-1/3'>
                    Date
                    </p>

                    <p className='w-1/3'>
                    Description 
                    </p>

                    <p className='w-1/3'> 
                    Amount
                    </p>
                </div>

                <div className='w-full p-2 flex flex-col gap-3'>
                {data.map((item, index) => (
                    <div key={index} className='w-full flex items-start justify-between text-sm font-medium 
                    md:text-base'>
                        <p className='w-1/3'>
                        {item.Date}
                        </p>
                        <p className='w-1/3'>
                        {item.Description}
                        </p>
                        <p className='w-1/3'>
                        N{item.Amount}
                        </p>
                    </div>
                ))}
                </div>
            </div>

            <button className='border border-[#069125] text-[#069125] font-medium py-2 px-3 rounded-lg mt-4'>
            Talk to a Doctor
            </button>

        </div>
    )
}

export default Payment
