import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { getToken } from '../utils/tokenHelper';  // Import the getToken function

const ChatHistory = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchChatHistory = async () => {
      setIsLoading(true);

      const token = getToken();  // Get the token from localStorage
      if (!token) {
        setError('No token found');
        setIsLoading(false);
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      try {
        const response = await fetch("https://api.quickcare.ng/api/v1/user/conversations", requestOptions);
        const result = await response.json();
        console.log('Server response:', result); // Log the server response

        // Check if the result contains chat history data under `data`
        if (result && result.data && Array.isArray(result.data)) {
          const extractedChatHistory = result.data.map(entry => {
            const date = new Date(entry.date);
            return {
              date: date.toISOString().split('T')[0],
              time: date.toTimeString().split(' ')[0].slice(0, 5),
              response: entry.response,
              symptoms: entry.symptoms // Include symptoms
            };
          });
          setChatHistory(extractedChatHistory);
        } else {
          setError("Chat history not found in the response");
        }
      } catch (error) {
        console.error('Fetch error:', error); // Log fetch error
        setError(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChatHistory();
  }, []);

  return (
    <div className='flex flex-col gap-4 w-full'>
      <h1 className='font-semibold text-2xl'>Chat History</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {isLoading ? (
        <div className='flex justify-center'>
          <CircularProgress /> {/* Show loading spinner when isLoading is true */}
        </div>
      ) : (
        <div className='overflow-x-auto w-full'>
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
              <tr className='w-full'>
                <th scope="col" className='px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider'>
                  Date
                </th>
                <th scope="col" className='px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider'>
                  Issue
                </th>
                <th scope="col" className='px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider'>
                  Summary
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-600'>
              {chatHistory.map((entry, index) => (
                <tr key={index}>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-black'>
                    {entry.date} <br /> {entry.time}
                  </td>
                  <td className='px-6 py-4 text-sm text-black'>
                    {entry.symptoms ? entry.symptoms : 'Test'}
                  </td>
                  <td className='px-6 py-4 text-sm text-black overflow-hidden text-ellipsis'>
                    {entry.response}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ChatHistory;
