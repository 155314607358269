import React, { useState } from 'react';
import Header from '../component/Header';
import ChatWithAI from '../component/ChatWithAI';
import Sidebar from '../component/Sidebar';


const ChatAI = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => { 
        setIsSidebarOpen(!isSidebarOpen);
    };
 
    return (
        <div className='w-full bg-[#F9FAFB]'>
            <div className='fixed w-full z-20'>
                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  
            </div>

            <div className={`pt-16 md:pt-20 w-full ${isSidebarOpen ? 'md:pt-20 w-full' : 'w-full'}`}>
                <div className={`${isSidebarOpen ? 'md:block z-50' : 'hidden  z-50'}`}>
                    <Sidebar />
                </div>
               
                <div className='h-full w-full'>
                 <ChatWithAI />
                </div>
            </div>
        </div>
    )
}

export default ChatAI
